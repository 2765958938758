import { decode } from "base64-arraybuffer";
import { StatusCodes } from "http-status-codes";
import fileDownload from "js-file-download";

import { API_BASE_URL } from "../configuration/config";
import { BaseService } from "./base-service";

export interface BulkImportFile {
  fileName?: string;
  resultsFile?: string;
  uploadedDate?: Date;
}

export interface GetAllBulkFilesResponse {
  totalResults: number;
  files: BulkImportFile[];
}

export interface BulkImportFileResponse {
  okCount: number;
  errorCount: number;
  fileResultName: string;
}

/**
 * A service for downloading the license template 
 */
export class FilesService extends BaseService {


  /**
   * Singleton instance for download service
   */
  public static instance = new FilesService(API_BASE_URL);

  public async getImportedFiles(): Promise<GetAllBulkFilesResponse> {
    const response = await this.fetch("/bulkimport/all");
    if (response.status !== StatusCodes.OK) {
      throw new Error("An error occurred when retrieving the bulk files.");
    }
    const jsonResponse = await response.json();
    return jsonResponse as GetAllBulkFilesResponse;
  }

  public async uploadBulkFile(file: File): Promise<BulkImportFileResponse> {
    console.log(`File name: ${file.name}`);
    const content = await file.arrayBuffer();

    const response = await this.fetch('/bulkimport', {
      method: 'POST',
      body: content,
      headers: {
        "x-file-name": file.name,
        "Content-Type": file.type,
      },
    });

    return response.json() as unknown as BulkImportFileResponse;
  }

  public async downloadBulkImportFile(name: string, type: string = "original") {
    const response = await this.fetch(`/bulkimport?fileName=${name}&type=${type}`);

    if (response.status !== StatusCodes.OK) {
      throw new Error("An error occurred when downloading the bulk file.");
    }

    console.log(`Response content ${response.body}`);

    const buffer = await response.arrayBuffer();

    fileDownload(buffer, name);
  }
  
  public async downloadItemsReport(type: string = "itse") {
    const response = await this.fetch(`/ito/items/download?type=${type}`);

    if (response.status !== StatusCodes.OK) {
      throw new Error("An error occurred when downloading the items report.");
    }

    const { fileContent, fileName} = await response.json();

    if (!fileContent) {
      throw new Error("Items export is empty");
    }

    fileDownload(decode(fileContent), fileName);
  }
}
