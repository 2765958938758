import { CollectionPreferencesProps, TableProps } from "@amzn/awsui-components-react";
import { NonCancelableEventHandler } from "@amzn/awsui-components-react/polaris/internal/events";
import { buildColumnDefinitions, buildVisibleContentOptions, defaultPagingOptions } from "common";
import { Preferences } from "common/ui-helpers";
import { useFlashBarItemsActions } from "common/UseFlashBarItems/useFlashBarItems";
import { CurrentPage } from "common/utils/config-models";
import { ColumnSetting } from "common/utils/config-models";
import { usePreferences } from "hooks/usePreferences";
import { useEffect, useState } from "react";
import { BulkImportFileResponse, FilesService, GetAllBulkFilesResponse } from "services/files-service";

import importsTableConfig from "./imports.table-config.json";

const columns: ColumnSetting[] = importsTableConfig.columns;

export interface ImportsTableProps {
    useFlashBarItemsActions: useFlashBarItemsActions;
}


export interface useImportsTableResult {
    actions: {
        onPreferencesConfirmHandler: NonCancelableEventHandler<CollectionPreferencesProps.Preferences<any>>;
        handleFileUpload: () => void;
        handleFileDownload: (name: string, type?: string) => void;
    };
    state: {
        data: GetAllBulkFilesResponse;
        pagesCount: number;
        loading: boolean;
        processingFile: boolean;
        currentPreferences: Preferences;
        currentPageIndex: CurrentPage;
        currentColumnDefinitions: TableProps.ColumnDefinition<any>[];
        visibleContentColumnOptions: CollectionPreferencesProps.VisibleContentOption[];
    }
}

export const useImportsTable = (props: ImportsTableProps) => {
    const [data, setData] = useState<GetAllBulkFilesResponse | undefined>(undefined);
    const [currentPageIndex, ] = useState<CurrentPage>({ index: 1, isPageReset: true });
    const [loading, setLoading] = useState(false);
    const [processingFile, setProcessingFile] = useState(false);

    // Ready to display
    const pagesCount = 0;

    const PREFERENCES_KEY = "ImportssPreferences";
    const visibleContentColumnsOptions = buildVisibleContentOptions(columns);

    // Preferences
    const { currentPreferences, onPreferencesConfirmHandler } = usePreferences({
        preferencesKey: PREFERENCES_KEY,
        refreshData: () => {},
        columnsSettings: columns,
        defaultPaging: defaultPagingOptions[0].value,
    });

    const doGetBulkImportFiles = async () => {
        setLoading(true);
        setData(await FilesService.instance.getImportedFiles());
        setLoading(false);
    };

    const currentColumnDefinitions = buildColumnDefinitions(columns, currentPreferences.visibleContent);

    const handleFileUpload = async (e: any) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            try {
                setProcessingFile(true);
                const response = await FilesService.instance.uploadBulkFile(selectedFile) as BulkImportFileResponse;
                setProcessingFile(false);
                props.useFlashBarItemsActions.addSuccessFlashBarItem(`File processed successfully. Rows processed: ${response.okCount}`);
                await doGetBulkImportFiles();
            }
            catch (err) {
                const error = err as Error;
                props.useFlashBarItemsActions.addErrorFlashBarItem("Error processing the file");
                console.error(`Error processing the file: ${error.message}`);
            }
            finally {
                e.target.value = null;
            }
        }
    };

    const handleFileDownload = async(name: string, type?: string) => {
        await FilesService.instance.downloadBulkImportFile(name, type);
    }

    useEffect(() => {
        async function fetchData() {
            await doGetBulkImportFiles();
        }
        fetchData();
      }, []);

    return {
        actions: {
            onPreferencesConfirmHandler,
            handleFileUpload,
            handleFileDownload
        },
        state: {
            data,
            pagesCount,
            loading,
            processingFile,
            currentPreferences,
            currentPageIndex,
            currentColumnDefinitions,
            visibleContentColumnsOptions
        }
    }
}