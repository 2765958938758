import {
    Box,
    Button,
    ButtonDropdown,
    CollectionPreferences,
    Flashbar,
    Header,
    Pagination,
    SpaceBetween,
    Spinner,
    Table,
} from "@amzn/awsui-components-react";
import { defaultPagingOptions } from "common/utils";
import { FunctionComponent } from "react";

import EmptyTableBox from "../../common/EmptyTableBox/EmptyTableBox";
import { getString } from "../../common/ui-string-labels/ui-strings-utils";
import { ImportsTableProps, useImportsTable } from "./useImportsTable";

export const ImportsTable: FunctionComponent<ImportsTableProps> = (props) => {
    const { actions, state } = useImportsTable(props);
    const { onPreferencesConfirmHandler } = actions;
    const { pagesCount, currentColumnDefinitions, currentPageIndex, currentPreferences, loading, processingFile, visibleContentColumnsOptions } = state;

    return (
        <Box data-testid="imports-box">
            <input
                type="file"
                accept="*/*"
                onChange={actions.handleFileUpload}
                style={{ display: 'none' }} // Hide the input
                id="file-input"
            />
            <SpaceBetween direction="vertical" size="s">
                {processingFile && <Flashbar
                    items={[
                        {
                            type: 'info',
                            dismissible: false,
                            content: (
                                <>
                                    <SpaceBetween direction="horizontal" size="xs"><Spinner></Spinner>{getString("imports.processing")}</SpaceBetween>
                                </>
                            ),
                            id: 'processingMessage',
                        }
                    ]}
                ></Flashbar>}
                <Table
                    data-testid="imports-table"
                    loading={loading}
                    header={
                        <SpaceBetween size="m">
                            <Header
                                data-testid="imports-table-header"
                                variant="awsui-h1-sticky"
                                actions={
                                    <>
                                        <SpaceBetween direction="horizontal" size="xs">
                                            <ButtonDropdown
                                                onItemClick={() => {
                                                    const fileInput = document.getElementById('file-input');
                                                    if (fileInput) {
                                                        fileInput.click();
                                                    }
                                                }}
                                                items={[
                                                    {
                                                        text: getString("imports.table.createSubLines"),
                                                        id: "btn-create-sub-lines",
                                                        disabled: processingFile,
                                                    },
                                                    {
                                                        text: getString("imports.table.updateSubLines"),
                                                        id: "btn-update-sub-lines",
                                                        disabled: processingFile
                                                    }
                                                ]}
                                            >
                                                {getString("imports.table.sublines")}
                                            </ButtonDropdown>
                                            <Button
                                                data-testid="imports-assignee-button"
                                                variant="normal" >
                                                {getString("imports.table.asignee")}
                                            </Button>
                                            <Button
                                                data-testid="imports-comments-button"
                                                variant="normal" >
                                                {getString("imports.table.comments")}
                                            </Button>
                                        </SpaceBetween>
                                    </>
                                }
                            >
                                {getString("imports.table.headerTitle")}
                            </Header>
                            <Box color="text-status-inactive">{getString("imports.table.headerDescription")}</Box>
                        </SpaceBetween>
                    }
                    pagination={
                        <Pagination
                            currentPageIndex={currentPageIndex.index}
                            pagesCount={pagesCount}
                            ariaLabels={{
                                nextPageLabel: getString("common.pagination.nextPage"),
                                previousPageLabel: getString("common.pagination.nextPage"),
                                pageLabel: (pageNumber: number) =>
                                    getString("common.pagination.currentPage", undefined, { pageNumber, pagesCount }),
                            }}
                        />
                    }
                    empty={<EmptyTableBox />}
                    stripedRows={currentPreferences.stripedRows}
                    preferences={
                        <CollectionPreferences
                            title={getString("common.preferences.title")}
                            confirmLabel={getString("common.preferences.confirmLabel")}
                            cancelLabel={getString("common.preferences.cancelLabel")}
                            pageSizePreference={{
                                title: getString("common.preferences.pageSize.title"),
                                options: defaultPagingOptions,
                            }}
                            stripedRowsPreference={{
                                label: getString("common.preferences.stripedRows.label"),
                                description: getString("common.preferences.stripedRows.description"),
                            }}
                            visibleContentPreference={{
                                title: getString("common.preferences.visibleContentPreference.title"),
                                options: [
                                    {
                                        label: getString("common.preferences.visibleContentPreference.columns"),
                                        options: visibleContentColumnsOptions,
                                    },
                                ],
                            }}
                            preferences={currentPreferences}
                            onConfirm={onPreferencesConfirmHandler}
                        />
                    }
                    columnDefinitions={currentColumnDefinitions}
                    loadingText={getString("common.loading")}
                    items={state.data?.files ?? []}
                    resizableColumns={true}
                    variant="full-page"
                    wrapLines
                />
            </SpaceBetween>
        </Box>
    );
};

export default ImportsTable;
